import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"
import MediDesignProcess from "../../components/SVG/medication-design-process"
import MedicationSol1 from "../../components/SVG/medication-sol-1"
import MedicationSol2 from "../../components/SVG/mediaction-sol-2"

// img
import MediBannerimg from "../../../images/uploads/medicationtracker/medication-banner.png"
import MediAbout1 from "../../../images/uploads/medicationtracker/medi-about-1.png"
import MediAbout2 from "../../../images/uploads/medicationtracker/medi-about-2.png"
import MediAbout3 from "../../../images/uploads/medicationtracker/medi-about-3.png"
import MediChallangeBg from "../../../images/uploads/medicationtracker/medi-challange-bg.png"
import mediSol3 from "../../../images/uploads/medicationtracker/medi-sol-3.png"

import mediSli1 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-1.png"
import mediSli2 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-2.png"
import mediSli3 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-3.png"
import mediSli4 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-4.png"
import mediSli5 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-5.png"
import mediSli6 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-6.png"
import mediSli7 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-7.png"
import mediSli8 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-8.png"
import mediSli9 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-9.png"
import mediSli10 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-10.png"
// import mediSli13 from "../../../images/uploads/medicationtracker/decodeup-medication-tracker-ss-as.png"


import addcs1 from "../../../images/uploads/decodeup-mahoni-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-greenwood-additional-case-study.png"


const MedicationTracker = (props) => {
  const [margin, setMargin] = useState()
  const flRef = useRef()
  const flmedi = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  const options = {
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }
  const mediOptions = {
    lazyLoad: true,
    prevNextButtons: false,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  const onMouseEnter1 = () => {
    flmedi.current.flkty.player.play()
  }
  const onMouseLeave1 = () => {
    flmedi.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "medicationtracker"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  A PCP in Your Pocket
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Medications Tracker
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  Creating a mobile app that could help patients keep track of
                  their medicine really excited us and DecodeUp was eager to get
                  started on the project. The numerous demands of the app proved
                  to be challenging and in classic DeocodeUp fashion we faced
                  the challenge head on.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Leonce
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      United States of America
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      UI/UX Design, Development, Maintenance and Support
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Android, Kotlin, Firebase
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Medical & Healthcare
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section>
          <div className="container mx-auto">
            <img
              className="w-full h-full medi-banenr-img"
              src={MediBannerimg}
              alt="Medication Tracker DecodeUp Technologies"
            />
          </div>
        </section>

        {/* About Project section */}
        <section className="py-10 lg:py-20 ">
          <div className="container mx-auto">
            <div className="pb-8 lg:pb-20 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Setup schedules and notification for your medications easily
                  with this mobile or wearable digital device.
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Medications tracker is an app that we built to solve the need
                  of helping people to maintain their schedules for medications
                  every day so people can maintain their health by following
                  their proper medicine schedules.
                </p>

                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  When someone is suffering from diseases and needs to strictly
                  follow their medication schedules at this app will work like a
                  personal doctor and help people follow the doctor's
                  instructions for medication intake.
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  The application keeps record of all medication that patients
                  add and notifies them on time so they never miss a medication
                </h3>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The client Leonce Song-Naba came up with one idea that he
                  wanted to create an application freely available in the app
                  market where customers can add their medication information
                  such as the Name, color of medicine, type of medicine (like a
                  tablet/liquid etc) and then set up a reminder in a custom way
                  like once a day/month/week/year, Repeat that reminder, and
                  create multiple reminders for a single day in repeat mode.
                  With all those options, and by considering all his needs, we
                  started planning this application.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* About project Slider */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div
              id="myid"
              onMouseEnter={onMouseEnter1}
              onMouseLeave={onMouseLeave1}
            >
              <Flickity
                className={"medication-about-slider"}
                elementType={"div"}
                options={mediOptions}
                ref={flmedi}
              >
                <div className="medi-about-img-div">
                  <img
                    className="w-full h-full object-cover"
                    src={MediAbout1}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                </div>
                <div className="medi-about-img-div">
                  <img
                    className="w-full h-full object-cover"
                    src={MediAbout2}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                </div>
                <div className="medi-about-img-div">
                  <img
                    className="w-full h-full object-cover"
                    src={MediAbout3}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                </div>
              </Flickity>
            </div>
          </div>
        </section>

        {/* Challanges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full">
              <h2 className="mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left lg:text-center">
                The Challenges
              </h2>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-2/29 lg:max-w-3-68">
                <img
                  src={MediChallangeBg}
                  alt="Medication Tracker Decodeup Technologies"
                />
              </div>
              <div className="w-full sm:w-7/10 flex flex-wrap">
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b md:border-b-0 medi-challenge-content">
                  <span className="mb-2 block text-cyan font-poppings-bold medi-span text-p3 md:text-sh3">
                    01
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    When the client shared all his requirements with us the team
                    had to start analyzing the idea and making a plan for the
                    number of screens and the navigation flow where customers
                    could add the schedule within a few seconds without
                    confusion or delay.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 md:border-l medi-challenge-content">
                  <span className="mb-2 block text-cyan font-poppings-bold medi-span text-p3 md:text-sh3">
                    02
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The application should attract patients who take medication
                    and need schedules for things like Medicines, Injections,
                    Drops, Dressings etc...
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full border-t border-b medi-challenge-content">
                  <span className="mb-2 block text-cyan font-poppings-bold medi-span text-p3 md:text-sh3">
                    03
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The Application should have the ability to remind patients
                    about their medication a number of minutes before schedule
                    (This will be set up by patients while adding their
                    schedule.) so they remain prepared. For example, if they
                    have to take any juice or food with medicines or if they
                    have to take it on an empty stomach they will know to do so.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b md:border-b-0 medi-challenge-content">
                  <span className="mb-2 block text-cyan font-poppings-bold medi-span text-p3 md:text-sh3">
                    04
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The application should be easy to set up so even elderly
                    people can also use it without assistance. The Notifications
                    should work smoothly.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 md:border-l  medi-challenge-content">
                  <span className="mb-2 block text-cyan font-poppings-bold medi-span text-p3 md:text-sh3">
                    05
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The System must be compatible for notifications on
                    smartwatches so if the patient wears a Smart Watch the
                    system will send notifications to that Watch been when the
                    patient is away from the phone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Design Process */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full">
              <h2 className="mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left lg:text-center">
                Design Process
              </h2>
            </div>
            <div className="w-full h-full">
              <MediDesignProcess />
            </div>
          </div>
        </section>

        {/* Solution */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full">
              <h2 className="mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left lg:text-center">
                The Solutions
              </h2>
            </div>

            <div className="pb-8 lg:pb-20 flex flex-wrap sm:flex-nowrap items-center gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <MedicationSol1 />
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  The Team at DecodeUp created a functional navigation flow for
                  the app on Figma and articulates a nice app via code.
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The team at DecodeUp is completely confident with the plan
                  they created for this platform and possess a clear roadmap
                  moving forward.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We created an attractive and simple user experience within the
                  app so anyone can set medication tracking easily for their all
                  medication reminders with different parameters like shape,
                  colour, form (Liquid or solid), Size etc. It helps users
                  follow schedules throughout the day or night.
                </p>
              </div>
            </div>
            <div className="pb-8 lg:pb-20 flex flex-wrap sm:flex-nowrap items-center gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2 order-2">
                <MedicationSol2 />
              </div>
              <div className="w-full lg:w-1/2 order-1">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Never miss a medication
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The Team created an application where users can register
                  themselves and maintain their medication schedule in seconds
                  while letting the app do the rest.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The smart watch notification was implemented so users can stay
                  aware of notifications and not miss their schedule in any
                  case.
                </p>
              </div>
            </div>

            <div className="w-full max-w-8-80 mx-auto text-center">
              <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                Better design and better performance
              </h3>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Like most projects, we focused on the Medication tracker content
                hierarchy to create a seamless experience.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                We love building powerful design systems. Our goal is to make
                reusable components that deliver predictable user experiences.
                We also create design systems that clients can customize with
                ease on new screens following the project launch.
              </p>
            </div>
            <div className="mt-16 w-full">
              <img
                className="mx-auto"
                src={mediSol3}
                alt="Medication Tracker Decodeup Technologies"
              />
            </div>
          </div>
        </section>

        {/* Typographics */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="lg:w-2/5 mx-auto text-center mb-6 md:mb-10 medi-typo">
              <div className="w-full">
                <h3 className="mb-8  xl:mb-16 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & Colors
                </h3>
              </div>
              <div className="w-full">
                <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Poppins
                </h3>
                <h5 className="mb-1 text-term-primary medi-typo-inner">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h5 className="mb-1 text-term-primary medi-typo-inner">
                  abcdefghijklmnopqrstuvwxyz
                </h5>
                <h5 className="mb-1 text-term-primary medi-typo-inner">
                  1234567890
                </h5>
              </div>
              <div className="mt-6 flex flex-wrap sm:flex-nowrap gap-6 justify-center">
                <div className="medi-color-plate">
                  <h5 className="text-white font-poppings-bold text-center">
                    #2F89FF
                  </h5>
                </div>

                <div className="medi-color-plate medi-color-plate-2">
                  <h5 className="text-white font-poppings-bold text-center">
                    #263238
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="w-full sm:w-2/3 lg:max-w-3xl text-center mx-auto">
              <h2 className="mb-4 lg:mb-8 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                The team at DecodeUp created a mobile app that looks
                contemporary, streamlined and includes design-rich features that
                are attractive to customers while setting each reminder.
              </p>

              <div className="w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  People loved the design and user experience because it helped
                  them stay on track feels like a personal doctor.
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-cor mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli1}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli2}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli3}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli4}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli5}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli6}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli7}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli8}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli9}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli10}
                    alt="Medication Tracker Decodeup Technologies"
                  />
                  {/* <img
                    className="cs-carousel-image rounded-2xl "
                    src={mediSli13}
                    alt="Medication Tracker Decodeup Technologies"
                  /> */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}

        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              {/* <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/beauty-products-ecommerce-store"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Beauty Products eCommerce Website
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs1}
                      alt="USA Mahoni Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div> */}
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/school-bus-pass-idcards-management-system"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    School Bus Pass & ID Cards Management System
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs2}
                      alt="Greenwood Bus Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default MedicationTracker

export const pageQuery = graphql`
query medicationstrackerpagequery {
  pageData: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/case-studies/medications-tracker.md/"}}
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}
`